<template>
  <section id="home-dashboard">
    <b-tabs pills>

      <!-- Tab: Inputs S&OP -->
      <b-tab active>
        <template #title>
          <feather-icon icon="DatabaseIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Inputs S&OP</span>
        </template>            

        <!--Carga de inputs -->
        <b-row>
          <b-col cols="12">
            <data-gathering></data-gathering>
          </b-col>
        </b-row>

        <!-- Fases/procesos -->
        <b-row>
          <b-col
            cols="12"
          >
            <timeline-pipeline></timeline-pipeline>
          </b-col>
        </b-row>

      </b-tab>
      
      <!-- Tab: PowerBI + Inputs -->
      <!--       
      <b-tab>
        <template #title>
          <feather-icon icon="DatabaseIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">PowerBI</span>
        </template>

        <b-row>
          <b-col cols="12">
            <dashboard-pbi></dashboard-pbi>
          </b-col>
        </b-row>

      </b-tab>
      -->

      <!-- Tab: Ultimo SOP Cerrada -->
      <b-tab>
        <template #title>
          <feather-icon icon="DatabaseIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Última S&OP Cerrada</span>
        </template>

        <b-row>
          <b-col cols="12">
            <comparacion-sop-fc></comparacion-sop-fc>
          </b-col>
        </b-row>
      </b-tab>

    </b-tabs>
    
  </section>
</template>

<script>
import {
  BCard, BCardText, BLink, BRow, BCol,
  BTab, BTabs, BCardTitle
} from 'bootstrap-vue'
import { ref } from 'vue'
import { kFormatter } from '@core/utils/filter.js'
import DataGathering from '@/modules/data-gathering/DataGathering.vue'
import AnalyticsTimeline from '@/modules/analytics/AnalyticsTimeline.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import CardAdvanceChat from '@/modules/chat/CardAdvanceChat.vue'
import TimelinePipeline from '@/modules/timeline/TimelinePipeline.vue'
import DashboardPbi from '@/modules/visuals/Dashboard/DashboardPbi.vue'
import ComparacionSopFc from '@/modules/visuals/ComparacionSopFc.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCardTitle,
    DataGathering,
    AnalyticsTimeline,
    StatisticCardWithAreaChart,
    CardAdvanceChat,
    TimelinePipeline,
    DashboardPbi,
    ComparacionSopFc,
  },
  setup() {
    const data = ref({})

    const getFileData = () => {
      import('@/modules/fake-data/home-data.js').then((e) => {
        data.value = e.default
      });
    }

    getFileData()

    return {
      data,

      kFormatter,
    }
  }
}
</script>

<style>
.tab-content>.tab-pane:not(.active),
.pill-content>.pill-pane:not(.active) {
  display: block !important;
  height: 0;
  overflow-y: hidden;
}
</style>
