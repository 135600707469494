<template>
  <b-card no-body border-variant="primary" class="p-2 bcard-data-gathering">

    <b-card-title class="d-flex align-items-center justify-content-between">
      <div style="padding: 0 0 0 6%">
        {{ areaData.area_name }}
      </div>

      <!-- Options Card -->
      <b-dropdown v-if="checkPermissionLoadData" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-primary"
        size="sm" right class="dropdown-icon-wrapper">
        <template #button-content>
          <feather-icon icon="MoreVerticalIcon" size="20" />
        </template>

        <div v-if="showAddDeleteOption">
          <b-dropdown-item @click="addConfirmation">
            <feather-icon icon="UploadIcon" size="10" />
            <span class="ml-1">Agregar</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="$can('delete', 'Admin')" @click="deleteConfirmation">
            <feather-icon icon="XCircleIcon" size="10" />
            <span class="ml-1">Borrar</span>
          </b-dropdown-item>
        </div>

        <b-dropdown-item @click="downloadConfirmation">
          <feather-icon icon="DownloadIcon" size="10" />
          <span class="ml-1">Plantilla</span>
        </b-dropdown-item>

        <b-dropdown-item-button v-b-modal.data-gathering-clone @click="cloneData">
          <feather-icon icon="CopyIcon" size="10" />
          <span class="ml-1">Clonar</span>
        </b-dropdown-item-button>
      </b-dropdown>
    </b-card-title>

    <!-- <b-alert
      :show="dismissAlertCountDown"
      v-height-fade.appear
      dismissible
      class="mb-0"
      variant="warning"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        {{alertMessage}}
      </div>
    </b-alert> -->

    <div class="bcard-data-gathering-body">
      <div v-if="showUploadFile">
        <div v-if="showUploadFileDialog">
          <div v-if="showUploadFileProgress" class="text-center rounded show-upload-file-progress">
            <feather-icon icon="UploadCloudIcon" size="30" />
            <div class="mb-2">Subiendo archivo... {{ barValue + '%' }}</div>
            <b-progress min="1" max="100" :value="barValue" variant="success" height="10px" class="mx-n4"></b-progress>
          </div>
          <div v-else :id="`upload-file-confirm-${areaData.area_id}`" ref="upload-file-dialog" tabindex="-1" role="dialog"
            aria-modal="false" aria-labelledby="form-confirm-label" class="text-center">
            <b-img :src="require('@/assets/images/icons/xls.png')" width="100px" alt="excel file image" />
            <p><strong>{{ inputFile.name }}</strong></p>
            <p><strong>¿Desea subir el archivo?</strong></p>
            <div>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" class="mr-3"
                @click="onCancel">
                Cancel
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" @click="onOK">
                OK
              </b-button>
            </div>
          </div>
        </div>

        <div v-else>
          <b-form-file ref="refInputEl" v-model="inputFile" :accept="allowedExtensions" class="my-1 custom-input"
            @input="onInputFile">
            <template slot="placeholder">
              <div class="d-flex flex-column align-items-center">
                <feather-icon icon="UploadIcon" size="35" />

                <label>Arrastre y Suelte un archivo</label>

                <label>O</label>

                <b-button pill variant="primary" size="sm">
                  Buscar un archivo
                </b-button>
              </div>
            </template>

            <template slot="drop-placeholder">
              <div class="d-flex flex-column align-items-center">
                <feather-icon icon="UploadIcon" size="30" />

                <h5>Suelte para subir</h5>
              </div>
            </template>

          </b-form-file>
        </div>
      </div>
      <div v-else>
        <div class="d-flex flex-column align-items-center">
          <div v-if="showUploadedMultiplesFiles">
            <b-list-group>
              <b-list-group-item v-for="(fileData, idx) in areaData.file_data" :key="idx"
                class="d-flex align-items-center">
                <b-img :src="require('@/assets/images/icons/xls.png')" width="40px" height="40px" alt="excel file image"
                  class="mr-1" />

                <div>{{ fileData.name }}</div>

                <b-button v-if="$can('delete', 'Admin')" variant="flat" class="btn-icon p-0 ml-1"
                  @click="deleteFileData(areaData.area_id, fileData)">
                  <feather-icon icon="XCircleIcon" size="25" />
                </b-button>

              </b-list-group-item>
            </b-list-group>
          </div>
          <div v-else class="d-flex flex-column align-items-center">
            <b-img :src="require('@/assets/images/icons/xls.png')" width="120px" alt="excel file image" />
            <strong>{{ getFileName }}</strong>
          </div>
          <b-button class="mt-2" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
            :to="{ name: 'data-gathering-details', params: { fileId: areaData.file_id, areaId: areaData.area_id } }">
            Ver Detalle
          </b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardFooter, BCardBody, BImg, BFormFile,
  BButton, BProgress, BAlert, BDropdown, BDropdownItem, BDropdownItemButton, VBModal,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { ref, toRefs, inject, getCurrentInstance, computed } from 'vue'
import Ripple from 'vue-ripple-directive'
import { heightFade } from '@core/directives/animations'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BImg,
    BFormFile,
    BButton,
    BProgress,
    BAlert,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    'b-modal': VBModal,
    'height-fade': heightFade,
    Ripple,
  },
  props: {
    areaData: {
      type: Object,
      default: {}
    }
  },
  setup(props, context) {
    const inputFile = ref([])
    const refInputEl = ref(null)
    const barValue = ref(1)
    const dismissAlertTime = ref(60)
    const dismissAlertCountDown = ref(0)
    const showUploadFile = ref(true)
    const showUploadedMultiplesFiles = ref(true)
    const showUploadFileDialog = ref(false)
    const showUploadFileProgress = ref(false)
    const allowedExtensions = ref(".xlsx, .xls")
    const alertMessage = ref('')
    const dateSelected = inject("dateSelected")
    const showAddDeleteOption = ref(false)
    const inputErrorsData = ref(null)

    const vm = getCurrentInstance().proxy

    // props
    const { areaData } = toRefs(props)

    // computed
    const checkPermissionLoadData = computed(() => {
      if (vm.$can('read', 'Admin')) return true

      if (vm.$can('block', 'CargarDatos')) return false

      return vm.$can('modify', areaData.value.area_name)
    })

    const getFileName = computed(() => {
      if (areaData.value.file_data[0] == undefined) return ''
      const fileName = areaData.value.file_data[0].name
      if (fileName == null || fileName == undefined) {
        return ''
      }

      return fileName
    })

    // methods
    const onInputFile = () => {
      inputFile.value = refInputEl.value.files[0]

      // validate file extension
      const allowedExtensionsRxg = /(\.xls|\.xlsx)$/i;

      if (!allowedExtensionsRxg.exec(inputFile.value.name)) {
        vm.$swal({
          title: `Solo está permitido subir archivos excel`,
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
          willClose: () => inputFile.value = []
        })
        return
      }

      showUploadFileDialog.value = true
    }

    const onCancel = () => {
      showUploadFileDialog.value = false
      inputFile.value = []
    }

    const showErrorDetails = () => {
      if (inputErrorsData.value.warning == null || inputErrorsData.value.warning == undefined) {
        if (inputErrorsData.value.details == null || inputErrorsData.value.details == undefined || inputErrorsData.value.details.length == 0) return
        context.emit('set-errors-data', inputErrorsData.value.details)
      } else {
        context.emit('set-errors-data', inputErrorsData.value.warning)
      }
      vm.$bvModal.show('data-gathering-error-modal')
    }

    const onOK = async () => {
      try {
        inputErrorsData.value = null
        barValue.value = 1
        showUploadFileProgress.value = true

        const month = dateSelected.value.split('-')[0]
        const year = dateSelected.value.split('-')[1]

        let formData = new FormData();
        formData.append('excel_file', inputFile.value);
        formData.append('year', year);
        formData.append('month', month);
        formData.append('area_id', areaData.value.area_id);

        const response = await axios
          .post(`${process.env.VUE_APP_BASE_URL}/upload_excel`,
           //.post(`http://127.0.0.1:3100/upload_excel`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: function (progressEvent) {
                barValue.value = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
              }
            }
          )

        areaData.value.file_id = response.data.file_id
        areaData.value.file_data = response.data.file_data
        inputErrorsData.value = response.data

        if (inputErrorsData.value.warning.length > 0) {
          vm.$swal({
            title: `Archivo de ${areaData.value.area_name} Subido`,
            text: "pero se encontraron observaciones",
            icon: 'warning',
            confirmButtonText: 'Ver Detalle',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            allowOutsideClick: false,
            buttonsStyling: false,
            willClose: showErrorDetails
          })
        }

      } catch (error) {
        inputErrorsData.value = error.response.data

        vm.$swal({
          title: `Error! al subir archivo de ${areaData.value.area_name}`,
          text: error.response.data.error,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
          willClose: showErrorDetails
        })
        // showAlert('Error al subir el archivo')
      } finally {
        updateStatusCard()
        inputFile.value = []
        showUploadFileDialog.value = false
        showUploadFileProgress.value = false
      }
    }

    const countDownChanged = (countDown) => {
      dismissAlertCountDown.value = countDown
    }

    const showAlert = (message) => {
      alertMessage.value = message
      dismissAlertCountDown.value = dismissAlertTime.value
    }

    const updateStatusCard = () => {
      if (areaData.value.file_id.trim() === "" || areaData.value.file_id.trim() == "0") {
        showAddDeleteOption.value = false
        showUploadFile.value = true
      } else {
        showAddDeleteOption.value = true
        showUploadFile.value = false
        showUploadedMultiplesFiles.value = areaData.value.file_data.length > 1
      }
    }

    const addConfirmation = () => {
      vm.$swal({
        title: `¿Está seguro de que desea agregar otro archivo a ${areaData.value.area_name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          showUploadFile.value = true
        }
      })
    }

    const deleteConfirmation = () => {
      vm.$swal({
        title: `¿Está seguro de que desea borrar el archivo de ${areaData.value.area_name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const month = dateSelected.value.split('-')[0]
            const year = dateSelected.value.split('-')[1]

            await axios
              .post(`${process.env.VUE_APP_BASE_URL}/del_data`,
                {
                  year,
                  month,
                  area_id: areaData.value.area_id
                }
              )

            areaData.value.file_id = ""
            areaData.value.file_data = []

            vm.$swal({
              title: `Datos borrados de ${areaData.value.area_name}!`,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

          } catch (e) {
            vm.$swal({
              title: `Error! No se pudo borrar los datos de ${areaData.value.area_name}`,
              text: e.response.data.error,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          } finally {
            updateStatusCard()
          }
        }
      })
    }

    const downloadConfirmation = () => {
      vm.$swal({
        title: `¿Está seguro de que desea descargar la plantilla de ${areaData.value.area_name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const month = dateSelected.value.split('-')[0]
            const year = dateSelected.value.split('-')[1]

            const response = await axios
              .post(`${process.env.VUE_APP_BASE_URL}/getTemplate`,
                {
                  year,
                  month,
                  area_id: areaData.value.area_id
                },
                {
                  responseType: 'blob'
                }
              )

            const filename = response.headers.filename
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
          } catch (e) {
            vm.$swal({
              title: 'Error!',
              text: `No se pudo descargar la plantilla de ${areaData.value.area_name}`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        }
      })
    }

    const deleteFileData = (area_id, fileData) => {
      vm.$swal({
        title: `¿Está seguro de que desea borrar los datos de ${fileData.name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await axios
              .post(`${process.env.VUE_APP_BASE_URL}/del_file_data`,
                {
                  area_id,
                  file_id: fileData.file_id
                }
              )

            areaData.value.file_id = response.data.file_id
            areaData.value.file_data = response.data.file_data

            vm.$swal({
              title: `Datos borrados de ${fileData.name}!`,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

          } catch (e) {
            vm.$swal({
              title: 'Error!',
              text: `No se pudo borrar los datos de ${fileData.name}`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          } finally {
            updateStatusCard()
          }
        }
      })
    }

    const cloneData = () => {
      context.emit('clone-selected-area', areaData.value)
    }

    // onCreated
    updateStatusCard()

    return {
      inputFile,
      refInputEl,
      barValue,
      dismissAlertCountDown,
      showUploadFile,
      showUploadedMultiplesFiles,
      showUploadFileDialog,
      showUploadFileProgress,
      allowedExtensions,
      alertMessage,
      showAddDeleteOption,

      onInputFile,
      onCancel,
      onOK,
      countDownChanged,
      showAlert,
      addConfirmation,
      deleteConfirmation,
      downloadConfirmation,
      cloneData,
      deleteFileData,

      //computed
      checkPermissionLoadData,
      getFileName,
    }
  }
}
</script>

<style lang="scss" scoped>
.bcard-data-gathering {
  height: 350px;
}

.bcard-data-gathering-body {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.bcard-data-gathering-alert {
  font-size: 10px;
}

.show-upload-file-progress {
  width: 110px;
}
</style>

<style lang="scss">
.custom-input {
  height: 200px;
  border-style: dashed;
  border-width: 1px;

  .custom-file-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-file-input~.custom-file-label::after {
    display: none;
    content: "buscar";
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
