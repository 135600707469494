<template>
  <b-card class="text-center custom-timeline">

    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <timeline-modal></timeline-modal>
      <b-card-title>
        Fases S&OP
      </b-card-title>
      <b-card-body>
        <pipeline ref="pipeline" :x="parseInt(dataForm.x)" :y="parseInt(dataForm.y)" :data="dataTimeline" :showArrow="dataForm.showArrow"
          :ystep="parseInt(dataForm.ystep)" :xstep="parseInt(dataForm.xstep)" :lineStyle="dataForm.lineStyle" @select="showModal"/>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardTitle} from 'bootstrap-vue'
import { ref, getCurrentInstance, provide } from 'vue'
import TimelineModal from './TimelineModal.vue'
import Pipeline from './CustomTimeline/Pipeline.vue'
import gql from 'graphql-tag'
import CustomLoading from '@/modules/common/CustomLoading.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,

    TimelineModal,
    Pipeline,
    CustomLoading,
  },
  data() {
    return {
      data: [
        {
          name: "Start",
          hint: "1m23s",
          status: "start",
          next: [
            { index: 1, weight: 2 },
            { index: 2, weight: 2 }
          ]
        },
        {
          name: "Input Planning",
          hint: "2022-01-07",
          status: "success",
          next: [
            { index: 3, weight: 0 },
          ]
        },
        {
          name: "Input Controlling",
          hint: "2022-01-07",
          status: "failure",
          next: [{ index: 3, weight: 0 }]
        },
        {
          name: "Verify",
          hint: "2022-01-14",
          status: "paused",
          next: [
            { index: 4, weight: 0 },
            { index: 5, weight: 0 },
            { index: 6, weight: 0 },
          ]
        },
        {
          name: "Ventas",
          hint: "2022-01-14",
          status: "running",
          next: [
            { index: 7, weight: 0 }
          ]
        },
        {
          name: "Shopper",
          hint: "2022-01-14",
          status: "running",
          next: [
            { index: 7, weight: 0 }
          ]
        },
        {
          name: "Marketing",
          hint: "2022-01-14",
          status: "running",
          next: [
            { index: 7, weight: 0 }
          ]
        },
        {
          name: "Pre S&OP",
          hint: "2022-01-20",
          status: "success",
          next: [
            { index: 8, weight: 0 }
          ],
        },
        {
          name: "S&OP Executive",
          hint: "2022-01-27",
          status: "success",
          next: [
            { index: 9, weight: 0 }
          ]
        },
        { name: "End ", hint: "2m23s", status: "end", next: [] }
      ],
      form: {
        x: 50,
        y: 55,
        xstep: 120,
        ystep: 70,
        data: 0,
        showArrow: true,
        lineStyle: "default",
        from: 0,
        to: 0
      },
      msg: ""
    }
  },
  setup() {
    const isLoading = ref(true)
    const dataTimeline = ref([])
    const dataForm = ref(null)
    const selectedNode = ref(null)

    const vm = getCurrentInstance().proxy

    // provide
    provide('selectedNode', selectedNode)

    dataForm.value = {
      x: 50,
      y: 55,
      xstep: 200,
      ystep: 130,
      data: 0,
      showArrow: true,
      lineStyle: "default",
      from: 0,
      to: 0
    }

    const showModal = (node) => {
      if(!vm.$can('modify', 'Admin')) return
      console.log(node)
      selectedNode.value = node
      vm.$bvModal.show('timeline-modal')
    }

    const getStatus = (status) => {
      if(status == 1) return 'success'
      return 'running'
    }

    const formatTimeline = (data) => {
      return [
        {
          name: "Start",
          status: "start",
          next: [
            { index: 1, weight: 1 },
            { index: 2, weight: 1 }
          ]
        },
        {
          id: data[0].id,
          permissionId: data[0].permissionId,
          name: data[0].etapa,
          date: data[0].fecha,
          status: getStatus(data[0].estado),
          next: [
            { index: 3, weight: data[0].estado },
          ]
        },
        {
          id: data[1].id,
          permissionId: data[1].permissionId,
          name: data[1].etapa,
          date: data[1].fecha,
          status: getStatus(data[1].estado),
          next: [{ index: 3, weight: data[1].estado }]
        },
        {
          name: "Verify",
          status: data[0].estado == 1 &&  data[1].estado == 1 ? "success" : "running",
          next: [
            { index: 4, weight: data[0].estado == 1 &&  data[1].estado == 1 ? 1 : 0 },
            { index: 5, weight: data[0].estado == 1 &&  data[1].estado == 1 ? 1 : 0 },
            { index: 6, weight: data[0].estado == 1 &&  data[1].estado == 1 ? 1 : 0 },
          ]
        },
        {
          id: data[2].id,
          permissionId: data[2].permissionId,
          name: data[2].etapa,
          date: data[2].fecha,
          status: getStatus(data[2].estado),
          next: [
            { index: 7, weight: data[2].estado }
          ]
        },
        {
          id: data[3].id,
          permissionId: data[3].permissionId,
          name: data[3].etapa,
          date: data[3].fecha,
          status: getStatus(data[3].estado),
          next: [
            { index: 7, weight: data[3].estado }
          ]
        },
        {
          id: data[4].id,
          permissionId: data[4].permissionId,
          name: data[4].etapa,
          date: data[4].fecha,
          status: getStatus(data[4].estado),
          next: [
            { index: 7, weight: data[4].estado }
          ]
        },
        {
          id: data[5].id,
          permissionId: data[5].permissionId,
          name: data[5].etapa,
          date: data[5].fecha,
          status: getStatus(data[5].estado),
          next: [
            { index: 8, weight: data[5].estado }
          ],
        },
        {
          id: data[6].id,
          permissionId: data[6].permissionId,
          name: data[6].etapa,
          date: data[6].fecha,
          status: getStatus(data[6].estado),
          next: [
            { index: 9, weight: data[6].estado }
          ]
        },
        { name: "End ", hint: "2m23s", status: "end", next: [] }
      ]
    }

    // subscription
    vm.$apollo.addSmartSubscription('dataTimeline', {
      query: gql`
        subscription dataTimeline {
          view_get_current_timeline  {    
            id
            fecha
            etapa
            estado
            permissionId
          }
        }
      `,
      result ({data}) {
        isLoading.value = false
        dataTimeline.value = [...formatTimeline(data.view_get_current_timeline)] 
      },
    })

    return {
      isLoading,
      dataTimeline,
      dataForm,

      showModal,
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 1800px) {
  .custom-timeline {
    overflow: auto;
  }
}
</style>
