<template>
  <b-modal
    id="timeline-modal"
    ref="timelineModal"
    centered
    no-close-on-backdrop
    title="Info"
    ok-title="Actualizar"
    @ok="updateData"
    @show="getData"
    cancel-variant="outline-secondary"
  >
    <template #modal-ok>
      <div
        v-if="isLoadingButton"
      >
        <b-spinner
          small
        />
        Actualizando...
      </div>
      <div
        v-else
      >
        Actualizar
      </div>
    </template>

    <div
      v-show="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-show="!isLoading"
    >
      <b-card-title>
        Proceso: {{selectedNode == null ? '' : selectedNode.name}}
      </b-card-title>
      <b-card-text>
        <!-- Permission -->
        <b-form-group
          v-if="showBlockOption"
          label="Block Access"
          label-cols="6"
        >
          <v-select
            v-model="selectedStatusPermission"
            :clearable="false"
            :options="statusPermissions"
            :reduce="option => option.value"
          />
        </b-form-group>

        <!-- Process -->
        <b-form-group
          label="Status"
          label-cols="6"
        >
          <v-select
            v-model="selectedStatus"
            :clearable="false"
            :options="statusProcess"
            :reduce="option => option.value"
          />
        </b-form-group>
      </b-card-text>
    </div>
  </b-modal>
</template>

<script>
import { BButton, BModal, BCardText, BSpinner, BImg, BFormGroup, BCardTitle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, inject, getCurrentInstance } from 'vue'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  components: {
    BButton,
    BModal,
    BCardText,
    BSpinner,
    BImg,
    BFormGroup,
    BCardTitle,

    CustomLoading,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isLoadingButton = ref(false)
    const isLoading = ref(true)
    const showBlockOption = ref(false)
    const selectedStatusPermission = ref(0)
    const selectedStatus = ref(0)

    const vm = getCurrentInstance().proxy

    // inject
    const selectedNode = inject("selectedNode")

    const statusPermissions = [
      { label: 'Desactivado', value: 0 },
      { label: 'Activo', value: 1 },
    ]

    const statusProcess = [
      { label: 'Running', value: 0 },
      { label: 'Success', value: 1 },
    ]

    // methods
    const getData = async () => {
      try {
        isLoading.value = true
        showBlockOption.value = false

        let permissionID = selectedNode.value.permissionId
        let timelineID = selectedNode.value.id

        if (permissionID == null || permissionID == 0) {
          permissionID = 0
          showBlockOption.value = false
        } else {
          showBlockOption.value = true
        }

        const response = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/getinfo_timeline`, 
          {
            data: {
              permissionID,
              timelineID
            }
          })
        selectedStatusPermission.value = response.data.isBlocked
        selectedStatus.value = response.data.estado
      } catch(e) {
        console.log(e)
      } finally {
        isLoading.value = false
      }
    }

    const updateData = async (bvModalEvt) => {
      bvModalEvt.preventDefault()
      const btnModal = document.getElementById('timeline-modal___BV_modal_footer_').children[1]

      try {
        isLoadingButton.value = true
        
        btnModal.setAttribute('disabled', true)

        const permissionID = selectedNode.value.permissionId
        const timelineID = selectedNode.value.id
        const dataTimeline = {}

        dataTimeline["permission"] = {
          id: permissionID == null ? 0 : permissionID,
          isBlocked: selectedStatusPermission.value
        }

        dataTimeline["timeline"] = {
          id: timelineID,
          estado: selectedStatus.value
        }

        await axios
        .post(`${process.env.VUE_APP_BASE_URL}/setinfo_timeline`,{
          data: dataTimeline
        })

        vm.$swal({
          title: 'Datos Actualizados!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          willClose: ()=> {
            getData()
          }
        })
      } catch(e) {
        console.log(e)
        vm.$swal({
          title: 'Error al actualizar!',
          text: `Intente de nuevo`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        isLoadingButton.value = false
        btnModal.removeAttribute('disabled')
      }
    }

    return {
      isLoadingButton,
      isLoading,
      showBlockOption,
      selectedNode,
      statusPermissions,
      statusProcess,
      selectedStatusPermission,
      selectedStatus,

      getData,
      updateData,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.grid-container-fc-modal {
  width: 100%;
  height: 25vh;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }
}
</style>