<template>
  <div class="container-div">
    <span :class="getColor"></span>
    {{formatPercentage}}
  </div>
</template>

<script>
// your vue component
export default {
  // access any cell properties here
  props: ["rowIndex", "prop", "model"],
  name: "trafficLight",
  data() {
    return {
    };
  },
  computed: {
    getColor: function () {
      let val = this.model[this.prop]
      if (val >= 0) return "div-circle-green"
      return "div-circle-red"
    },
    formatPercentage: function () {
      return Number(this.model[this.prop]).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1}); 
    }
  },

};
</script>

<style scoped>
.container-div {
  display: flex;
  align-items: center;
}

.div-circle-red {
  margin-right: 5px;
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.div-circle-green {
  margin-right: 5px;
  height: 20px;
  width: 20px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.div-circle-yellow {
  margin-right: 5px;
  height: 20px;
  width: 20px;
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;
}
</style>