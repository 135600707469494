<template>
  <b-card
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <b-card-body>
        <b-row class="d-flex justify-content-center title-month">
          LAST S&OP {{currentMonth}}
        </b-row>

        <b-row class="mb-1">
          <b-button
          @click="saveData"
          :disabled="isDisabledSaveData"
          variant="primary"
          class="ml-1"
          >
            Save
          </b-button>
        </b-row>

        <v-grid
          ref="refGridCompracionSopFc"
          :source="source" 
          :columns="columns" 
          :columnTypes="pluginRevo"
          theme="material" 
          resize="true"
          autoSizeColumn = true
          class="grid-container-comparacion-sop-fc"
          @beforeeditstart="beforeEditStart"
          @focusout="commitCell"
        ></v-grid>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody,
  BRow,
} from 'bootstrap-vue'
import { ref, getCurrentInstance, computed } from 'vue'
import VGrid, { VGridVueTemplate } from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'
import TrafficLightComponent from './TrafficLightComponent.vue'
import axios from '@axios'
import { numericSort } from '@/libs/revo-grid/custom-sort'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,

    CustomLoading,
    VGrid,
  },
  setup(props) {
    const source = ref([])
    const isLoading = ref(true)
    const refGridCompracionSopFc = ref(null)
    const columns = ref([])
    const revoRowIndex = ref(null)
    const revoColName = ref(null)
    const modifiedData = ref([])
    const currentMonth = ref('000000')

    const pluginRevo = { 
      'numeric': new NumberColumnType('0,0'),
      'decimal': new NumberColumnType('0,0.00'),
      'percentage': new NumberColumnType('0,0[.]0 %'),
      'numeric_negative': new NumberColumnType('(0,0)'),
    }

    const vm = getCurrentInstance().proxy

    const getStyleGrid = (prop, model) => {

      let configStyles = {}

      if (prop == "lastyear"
      || prop == "dif_abs_lastyear"
      || prop == "var_porc_lastyear") {
        configStyles["background"] = "#e3dee2"
      } 

      if (prop == "sop_m1"
      || prop == "dif_abs_m1"
      || prop == "var_porc_m1") {
        configStyles["background"] = "#EDEDED"
      } 

      if (prop == "financial"
      || prop == "dif_abs_financial"
      || prop == "var_porc_financial") {
        configStyles["background"] = "#DDEBF7"
      }

      if(model.brand_category.toUpperCase() == 'TOTAL' 
      || model.brand_category.toUpperCase() == 'ZTOTAL') {
        configStyles["background"] = "#FFFF99"
        configStyles["font-weight"] = "bold"
      }

      if (prop == "bpu"
      || prop == "brand_category"
      || prop == "application_form"
      || prop == "comment") {
        return configStyles
      }
      
      configStyles["text-align"] = "right"

      return configStyles
    }

    columns.value = [
      {
        "name": "BPU",
        "prop": "bpu",
        "size": 150,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "pin": 'colPinStart',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "BRAND CATEGORY",
        "prop": "brand_category",
        "size": 200,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "pin": 'colPinStart',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "APPLICATION FORM",
        "prop": "application_form",
        "size": 220,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "pin": 'colPinStart',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "LAST S&OP PEN",
        "prop": "lastsop_pen",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "LAST S&OP EUR",
        "prop": "lastsop_eur",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "LAST YEAR",
        "prop": "lastyear",
        "size": 70,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "∆ ABS",
        "prop": "dif_abs_lastyear",
        "size": 80,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "∆ %",
        "prop": "var_porc_lastyear",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
        cellTemplate: VGridVueTemplate(TrafficLightComponent),
      },
      {
        "name": "S&OP M-1",
        "prop": "sop_m1",
        "size": 70,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "∆ ABS",
        "prop": "dif_abs_m1",
        "size": 80,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "∆ %",
        "prop": "var_porc_m1",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
        cellTemplate: VGridVueTemplate(TrafficLightComponent),
      },
      {
        "name": "FINANCIAL FC",
        "prop": "financial",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "∆ ABS",
        "prop": "dif_abs_financial",
        "size": 80,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "∆ %",
        "prop": "var_porc_financial",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
        cellTemplate: VGridVueTemplate(TrafficLightComponent),
      },
      {
        "name": "Comentario",
        "prop": "comment",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        }
      },
    ]

    // subscription
    vm.$apollo.addSmartSubscription('getComparacionSopFc', {
      query: gql`
        subscription getComparacionSopFc {
          Comparacion_SOP_M1_FC_BY_ORDER_view {
            id
            bpu
            brand_category
            application_form
            lastsop_pen
            lastsop_eur
            lastyear
            dif_abs_lastyear
            var_porc_lastyear
            sop_m1
            dif_abs_m1
            var_porc_m1
            financial
            dif_abs_financial
            var_porc_financial
            comment
          }
        }
      `,
      result ({data}) {
        isLoading.value = false
        currentMonth.value = data.Comparacion_SOP_M1_FC_BY_ORDER_view[0].id
        source.value = data.Comparacion_SOP_M1_FC_BY_ORDER_view

        // remove _typename
        for(let obj of source.value) {
          delete obj.__typename
        }
      },
    })

    const clearRowSelection = () => {
      revoRowIndex.value = null
      revoColName.value = null
    }
    
    const clearRevoValues = () => {
      clearRowSelection()
      modifiedData.value = []
    }
    
    const beforeEditStart = (event) => {
      revoRowIndex.value = event.detail.rowIndex
      revoColName.value = event.detail.prop
    }

    const commitCell = async (event) => {
      const originalData = await refGridCompracionSopFc.value.$el.getSource()
      const viewData = await refGridCompracionSopFc.value.$el.getVisibleSource()
      let rowData = viewData[revoRowIndex.value]
      let oldValue = rowData[revoColName.value]
      let newValue = event.target.value
      if (oldValue != newValue) {
        rowData[revoColName.value] = newValue
        modifiedData.value.push(Object.assign({}, rowData))
        refGridCompracionSopFc.value.$el.source = [...originalData]
      }
      clearRowSelection()
    }

    const saveData = async () => {
      if(modifiedData.value.length > 0) {
        vm.$swal({
          title: `¿Está seguro de que desea guardar los cambios?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            return axios
              .post(`${process.env.VUE_APP_BASE_URL}/upsert_comparacionSOP`,{
                "data": modifiedData.value
              })
              .then(response => {
                if (response.status != 200) {
                  throw new Error(response.statusText)
                }
                return response
              })
              .catch(error => {
                vm.$swal.showValidationMessage(`Request failed:  ${error}`)
              })
          },
        }).then( async (result) => {
          if (result.value) {
            vm.$swal({
              icon: 'success',
              title: 'Datos guardados!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              willClose: clearRevoValues
            })
          } 
        })
      }
    }

    // computed
    const isDisabledSaveData = computed(() => {
      return modifiedData.value.length <= 0
    })


    //onCreated
    clearRevoValues()

    return {
      source,
      columns,
      isLoading,
      refGridCompracionSopFc,
      pluginRevo,
      isDisabledSaveData,
      currentMonth,

      beforeEditStart,
      commitCell,
      saveData,
    }
  },
}
</script>

<style lang="scss">
.title-month {
  font-size: 35px;
  font-weight: bold;
}

.grid-container-comparacion-sop-fc {
  width: 100%;
  height: 83vh;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  revogr-header {
    line-height: 20px !important;

    .rgHeaderCell .header-content {
      white-space: normal !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .header-rgRow {
      height: 70px !important;;
    }
  }

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
