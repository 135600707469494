<template>
  <b-modal
    id="data-gathering-clone"
    ref="cloneModal"
    centered
    no-close-on-backdrop
    title="Clonar Datos"
    ok-title="Descargar"
    @ok="downloadFile"
    @show="cleanSelection"
    cancel-variant="outline-secondary"
  >
    <template #modal-ok>
      <div
        v-if="isLoadingButton"
      >
        <b-spinner
          small
        />
        Descargando...
      </div>
      <div
        v-else
      >
        Descargar
      </div>
    </template>

    <b-card-text>
      <span class="d-flex mb-1"><strong class="mr-1">Archivo:</strong> {{selectedArea.area_name}}</span>
      <span class="d-flex mb-1"><strong>Mes: </strong></span>
      <v-select
        v-model="selectedMonth"
        :options="optionsSelect"
        :clearable="false"
        label="mes"
        placeholder="Seleccione un mes"
      >
        <template v-slot:no-options>
          No hay datos
        </template>

        <template v-slot:option="option">
          <b-img
            :src="require('@/assets/images/icons/xls.png')"
            width="20px"
            alt="excel file image"
            class="mr-1"
          />
          {{ option.mes }}
        </template>
      </v-select>
    </b-card-text>
  </b-modal>
</template>

<script>
import { BButton, BModal, BCardText, BSpinner, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, toRefs, watch, getCurrentInstance } from 'vue'
import axios from '@axios'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BModal,
    BCardText,
    BSpinner,
    BImg,

    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    selectedArea: {
      type: Object,
      default: {}
    }
  },
  setup(props) {
    // props
    const { selectedArea } = toRefs(props)
    const selectedMonth = ref([])
    const cloneModal = ref(null)
    const isLoadingButton = ref(false)
    const optionsSelect = ref([])

    const vm = getCurrentInstance().proxy

    const downloadFile = async (bvModalEvt) => {
      const btnModal = document.getElementById('data-gathering-clone___BV_modal_footer_').children[1]
      try {
        bvModalEvt.preventDefault()

        if(selectedMonth.value.length === 0) {
          return
        }

        isLoadingButton.value = true

        btnModal.setAttribute('disabled', true)

        const response = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/clone_data`,
          {
            file_id: selectedMonth.value.file_id,
            area_id: selectedArea.value.area_id
          },
          {
            responseType: 'blob'
          }
        )

        const filename = response.headers.filename;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      } catch(e) {
        console.log(e)
        vm.$swal({
          title: 'Error!',
          text: `No se pudo clonar los datos `,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        btnModal.removeAttribute('disabled')
        isLoadingButton.value = false
      }
    }

    const cleanSelection = () => {
      selectedMonth.value = []
    }

    // load data to vue-select
    watch(selectedArea, (newValue, oldValue) => {
      optionsSelect.value = newValue.data
    })

    return {
      cloneModal,
      isLoadingButton,
      selectedMonth,
      optionsSelect,

      downloadFile,
      cleanSelection,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>